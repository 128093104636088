<template>
  <div class="DAV-workshop__space">
    <component-title>
      <h3 slot="title">페이지 선택</h3>
    </component-title>
    <div class="DAV-editPage">
      <ul class="DAV-editPage__list">
        <li
          class="DAV-editPage__item"
          v-for="(page, index) in getThemeLoadData.editablePages"
          :key="index"
        >
          <button @click="setEditPage(index)">{{ page.name }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleEditorContainer = createNamespacedHelpers("ModuleEditorContainer");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleEditorContainer.mapGetters(["getEditableIndex"])
  },
  methods: {
    // 페이지 선택
    setEditPage(index) {
      if (this.getEditableIndex !== index) {
        this.setEditableIndex(index);
        this.setEvent("page");
      }
      this.setPanel("ComponentList");
    },

    ...ModuleEditor.mapMutations(["setPanel"]),
    ...ModuleEditorContainer.mapMutations(["setEvent", "setEditableIndex"])
  },
  mounted() {},
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-editPage {
  z-index: 1;
  position: absolute;
  top: 56px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  padding: 8px 16px 16px;

  &__item {
    display: flex;
    align-items: center;
    justify-self: center;
    margin-top: 8px;
    border: 1px solid #dadada;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    button {
      width: 100%;
      height: 56px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 32px 0 16px;
      background-color: #ffffff;
      background-image: url($path + "bul_arr_next.svg");
      background-repeat: no-repeat;
      background-position: right 16px center;
      border-radius: 8px;
      text-align: left;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__space"
  }, [_c('component-title', [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("페이지 선택")])]), _c('div', {
    staticClass: "DAV-editPage"
  }, [_c('ul', {
    staticClass: "DAV-editPage__list"
  }, _vm._l(_vm.getThemeLoadData.editablePages, function (page, index) {
    return _c('li', {
      key: index,
      staticClass: "DAV-editPage__item"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.setEditPage(index);
        }
      }
    }, [_vm._v(_vm._s(page.name))])]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }